import React, { useState } from 'react'
import Button from './Button'

function A2HS() {
  const [renderButton, setRenderButton] = useState(false)

  if (typeof window === `undefined`) {
    return null
  }

  let deferredPrompt
  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault()
    deferredPrompt = e
    setRenderButton(true)
  })

  const installApp = () => {
    if (!deferredPrompt) return
    deferredPrompt.prompt()
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt')
      } else {
        console.log('User dismissed the A2HS prompt')
      }
      deferredPrompt = null
    })
  }

  if (renderButton) return <Button onClick={installApp}>Dodaj jako aplikację</Button>

  return null
}

export default A2HS
