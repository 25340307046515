import { lighten } from 'polished'

const colors = {
  primary: '#1f2859', // Color for buttons or links
  primaryLight: lighten(0.05, '#1f2859'),
  primaryStrongLight: 'rgba(232, 240, 255, 1)',
  bg: 'white', // Background color
  grey: {
    dark: 'rgba(0, 0, 0, 0.9)',
    default: 'rgba(0, 0, 0, 0.7)',
    light: 'rgba(0, 0, 0, 0.5)',
    ultraLight: 'rgba(0, 0, 0, 0.25)',
  },
  white: 'white',
}

const transitions = {
  normal: '0.5s',
}

const fontSize = {
  small: '0.9rem',
}

const fontFamily = {
  serif: `'Helvetica', 'Bitter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Arial', serif`,
  sansSerif: `'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
}

const breakpoints = {
  tablet: '1200px',
  phone: '600px',
}

let size = {
  left: `200px`,
  bottom: `200px`,
}

if (typeof window !== `undefined`) {
  size = {
    left: `${window.innerWidth / 3.5}px`,
    bottom: `${window.innerWidth / 3}px`,
  }
}

const theme = {
  size,
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: '1000px',
  baseFontSize: '18px',
}

export default theme
