const currentLanguage = 'pl'

const t = {
  pl: {
    title: 'PATYS.PL',
    subtitle: 'Programowanie, podróże, pasja',
    latestStories: 'Ostatnie posty',
    menu: {
      programming: 'Programowanie',
      blockchain: 'Blockchain',
      languages: 'Języki obce',
      mobile: 'Aplikacje mobilne',
      about: 'O mnie',
      lifestyle: 'Życie i podróże',
      outsideLink: 'Stary Blog',
    },
    footer: '2019 - Patryk "Patys" Szczygło.',
    rules: 'Zasady prywatności',
    categories: 'Kategorie',
    about: 'O mnie',
  },
}

export { t, currentLanguage }

export default t
