import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import { t, currentLanguage } from '../t/t'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const Item = styled.div`
  padding: 0.5rem 2rem;
  border-left: 1px solid #ccc;
  border-bottom: ${(props) => (!props.isFocused ? '0px' : '1px')} solid #ccc;
  /* background-color: ${(props) =>
    !props.isFocused ? props.theme.colors.primaryStrongLight : 'rgb(160, 202, 255)'}; */
  /* border-radius: 2rem; */
  margin-bottom: 0.5rem;
`

const getLink = (type) => `/categories/${kebabCase(type)}`

const Navigation = ({ currentLink }) => (
  <Wrapper role="navigation">
    <Item isFocused={currentLink === 'programming'}>
      <Link to={getLink('programming')}>{t[currentLanguage].menu.programming}</Link>
    </Item>
    <Item isFocused={currentLink === 'blockchain'}>
      <Link to={getLink('blockchain')}>{t[currentLanguage].menu.blockchain}</Link>
    </Item>
    <Item isFocused={currentLink === 'mobile'}>
      <Link to={getLink('mobile')}>{t[currentLanguage].menu.mobile}</Link>
    </Item>
    <Item isFocused={currentLink === 'lifestyle'}>
      <Link to={getLink('lifestyle')}>{t[currentLanguage].menu.lifestyle}</Link>
    </Item>
    <Item>
      <Link to="/about">{t[currentLanguage].menu.about}</Link>
    </Item>
  </Wrapper>
)

export default Navigation
