import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import { t, currentLanguage } from '../t/t'

const WrapperStretched = styled.ul`
  display: flex;
  flex: 1;
  list-style: none;
  justify-content: center;
  padding-left: 0px;
  background-color: transparent;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`

const Wrapper = styled.ul`
  display: flex;
  flex: 1;
  list-style: none;
  justify-content: space-around;
  padding-left: 0px;
  background-color: transparent;
  /* border-bottom-width: 1px; */
  /* border-bottom-style: solid;
  border-bottom-color: #ccc; */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-bottom-width: 0px;
    display: none; /* no menu on mobile */
  }
`

const Item = styled.li`
  padding: 0rem 1rem;
  margin-top: 0.5rem;
  font-size: 22px;
  line-height: 1rem;
  border-left: 1px #aaaaaa dashed;
  border-right: 1px #aaaaaa dashed;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 90vw;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-left: none;
    border-right: none;
    line-height: 2rem;
  }
`

const Title = styled.a`
  text-align: center;
  padding: 0rem 3rem;
  font-size: 30px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 90vw;
    text-align: center;
  }
`

const Menu = () => (
  <WrapperStretched role="navigation">
    <Title href="/">patys.pl</Title>
  </WrapperStretched>
)

export default Menu
