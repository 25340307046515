import React from 'react'
import styled from 'styled-components'

const RODO = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  padding: 5px;
  background-color: #fff;
  text-align: center;
`

/*
  <RODO>
    Strona używa plików cookies, między innymi od google lub discuss aby umożliwić działanie strony. Więcej szczegółów
    tutaj:&nbsp;
    <a href="https://blog.patys.pl/polityka-prywatnosci-rodo/">Zasady prywatności</a>
  </RODO>
*/

const RODOComponent = () => null

export default RODOComponent
