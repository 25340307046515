import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import { t, currentLanguage } from '../t/t'

import Subline from './Subline'

const Post = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.phone}) {
    flex-wrap: wrap;
  }
`

const Title = styled.h3`
  position: relative;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 0.25rem;
  a {
    color: ${(props) => props.theme.colors.grey.dark};
    &:hover {
      color: ${(props) => props.theme.colors.primaryLight};
    }
  }
`

const Initiale = styled.span`
  position: absolute;
  font-size: 4rem;
  transform: translate(-30%, -30%);
  opacity: 0.08;
  user-select: none;
  z-index: -1;
`

const Excerpt = styled.p`
  font-size: 17px;
  grid-column: -1 / 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const Content = styled.div`
  flex-grow: 4;
`

const Image = styled.img`
  flex-grow: 1;
  max-width: 200px;
  padding: 10px;
`

// <Image src={image} alt={slug} />

const Article = ({ title, date, excerpt, image, slug, timeToRead, categories }) => {
  const firstChar = title.charAt(0)

  return (
    <Post>
      <Row>
        <Content>
          <Title>
            <Initiale>{firstChar}</Initiale>
            <Link to={slug}>{title}</Link>
          </Title>
          <Subline>
            {date} &mdash; {timeToRead} min czytania &mdash; w{' '}
            {categories.map((cat, i) => (
              <React.Fragment key={cat}>
                {!!i && t[currentLanguage].menu[cat] && ', '}
                <Link to={`/categories/${kebabCase(cat)}`}>{t[currentLanguage].menu[cat]}</Link>
              </React.Fragment>
            ))}
          </Subline>
          <Excerpt>{excerpt}</Excerpt>
        </Content>
      </Row>
    </Post>
  )
}

export default Article

Article.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  image: PropTypes.string.isRequired,
}
